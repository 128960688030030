/**
 * Link
 */
export const GOOGLE_CALENDAR_LINK = `https://www.google.com/calendar/render?action=TEMPLATE&text=April+%26+Tito+Wedding&location=Bogor&dates=20221126T030000Z%2F20221126T070000Z`;
export const GOOGLE_MAPS_LINK = `https://goo.gl/maps/v8m4rPDrTMBrdWw17/`;

/**
 * Wedding time
 */
export const EPOCH_START_EVENT = 1669424400;
export const EPOCH_END_EVENT = 1669424400;
export const UTC_WEDDING_TIME = '2022-11-26:01:00:00Z';
