import React from 'react';
import { string, bool } from 'prop-types';
import { Link } from 'gatsby';

import { styWrapper, styFlex } from './styles';
import { Helmet } from 'react-helmet';

// function submit_form(){
//   var form = document.getElementById("my_form");
//   // form.submit();
//   alert("Your Message Sent");
// }

function ConfirmationSection({ isInvitation, guestName, codeLink, guestDesc }) {
  if (!isInvitation) return null;

  return (
    <div id="fh5co-started" className="fh5co-bg" css={styWrapper}>
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
            <h2 className="main-font">{` Konfirmasi kehadiran`}</h2>
            <Helmet>
            <script src="https://s.pageclip.co/v1/pageclip.js" charset="utf-8"></script>
            <link rel="stylesheet" href="https://s.pageclip.co/v1/pageclip.css" media="screen"></link>
            </Helmet>
            <form class="pageclip-form" action="https://send.pageclip.co/JAN0sOlVXkqKrWOgEBuugT4gIRNk8QIN/RSVP" method="post">

            <input type="hidden" name="guestName" value={guestName} />

            <div class="row wrapper">
              <input type="radio" name="RSVP" id="option-1" value="hadir" />
              <input type="radio" name="RSVP" id="option-2" value="tidak_hadir" />
                <label for="option-1" class="option option-1">
                    <span>Hadir 😁 </span>
                </label>
                <label for="option-2" class="option option-2">
                    <span>Tidak Hadir 😔</span>
                </label>
            </div>
            
            <div className="row" css={styFlex}>
            <br/>
           </div>


           <div class="row wrapper">
              <input type="radio" name="guest" id="option-3" value="1" />
              <input type="radio" name="guest" id="option-4" value="2" />
                <label for="option-3" class="option option-3">
                    <span>1</span>
                </label>
                <label for="option-4" class="option option-4">
                    <span>2</span>
                </label>
            </div>

            <div className="row" css={styFlex}>
            <br/>
           </div>

            <div className="row" css={styFlex}>
              <div className="col-sm-3">
                <button class="btn btn-default pageclip-form__submit" type="submit">RSVP</button>
              </div>
           </div>


          </form>


            <br/>

            <p>
              Atas kehadiran & do'a restu saudara/i, <br /> kami ucapkan terima kasih. Wassalamualaikum Warahmatullahi
              Wabarakatuh.
            </p>
          </div>
        </div>
        <div className="row" css={styFlex}>
          <div className="col-md-3">
            <Link to={`e-ticket?${codeLink}`}>
              <button className="btn btn-default btn-block">Lihat e-Ticket</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

ConfirmationSection.propTypes = {
  codeLink: string.isRequired,
  isInvitation: bool.isRequired,
  guestName: string.isRequired,
  guestDesc: string.isRequired,
};

export default React.memo(ConfirmationSection);
