// import ImgKembaliBerjumpa from '@assets/images/p-kembali-berjumpa.jpg';
// import ImgJakartaKroasia from '@assets/images/p-jakarta-kroasia.jpg';
// import ImgBoarding from '@assets/images/p-boarding-smg.jpg';
// import ImgKembaliKeIndonesia from '@assets/images/p-kembali-ke-indonesia.jpg';
// import ImgNdodokLawang from '@assets/images/p-ndodok-lawang.jpg';
import ImgTimeline1 from '@assets/images/timeline-1.jpg';
import ImgTimeline3 from '@assets/images/timeline-3.jpg';
import ImgTimeline4 from '@assets/images/timeline-4.jpg';
import ImgTimeline5 from '@assets/images/timeline-5.jpg';
import ImgTimeline6 from '@assets/images/timeline-6.jpg';

export const stories = [
  {
    title: 'Jumpa 2010, AN ENGLISH COURSE ',
    date: '2010',
    description:
      'Pertama kali Aisyah Aprilea berkenalan dengan lelaki bernama Prastito Arjandhi. HI STRANGER!',
    image: ImgTimeline1,
  },
  {
    title: 'WERE CLOSE BUT NOT CLOSE ENOUGH',
    date: '',
    description:
      'Setelah keluar dari bimbingan belajar bahasa inggris tersebut, masing masing bertemu kembali di tahun 2013. Yha! Just one message from April',
    image: '',
  },
  {
    title: '"PRIL.. WOULD YOU MIND TO BE MY GIRLFRIEND?',
    date: 'Oktober 2013',
    description:
      'Setelah 3 tahun berteman, 2 bulan pendekatan yang tidak disengaja serta sama sama single, We`ve decided to have a relationship more than a friend!',
    image: ImgTimeline3,
  },
  {
    title: 'LONG DISTANCE RELATIONSHIP Depok-Bandung',
    date: '2014',
    description:
      'April dan Tito saling berkomitmen untuk bisa berjalan berdampingan antara pendidikan dan kehidupan pribadi, banyak dinamika emosi seusia mereka saat itu.. sampai akhirnya 1 hari sebelum hari jadi kita 4 tahun ',
    image: "",
  },
  {
    title: 'WE STARTED TO DATING AGAIN',
    date: '2018',
    description:
      'November 2018, selama hampir satu tahun setelah putus kami tetap saling berkomunikasi hanya intensitas dan kadar nya tidak seperti dahulu sampai akhirnya kami memutuskan untuk menjalin hubungan kembali diiringi dengan memperbaiki cara kami berkomunikasi',
    image: ImgTimeline5,
  },
  {
    title: 'CLOSE THE MARKET',
    date: 'June 2022',
    description:
      'Sisi Romantis dari Prastito bukan yang seperti candle light dinner dengan solitaire ring, tetapi langsung ke jewellery place dan bertanya ke April kapan waktu yang tepat untuk kedua keluarga dapat bertemu 🥲',
    image: ImgTimeline6,
  },
];
