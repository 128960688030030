import { css } from '@emotion/core';
import Background from '@assets/images/gallery-5.jpg';

export const styWrapper = css`
  background-image: url(${Background});
  background-size: cover;
  background-position: center;

  p {
    color: rgba(255, 255, 255, 0.8) !important;
  }
  label {
    color: rgba(255, 255, 255, 0.8) !important;
  }

  .wrapper{
    display: inline-flex;
    background: #fff;
    height: 100px;
    width: 350px;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 5px;
    padding: 20px 15px;
    box-shadow: 5px 5px 30px rgba(0,0,0,0.2);
  }
  .wrapper .option{
    background: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 10px;
    border-radius: 5px;
    cursor: pointer;
    padding: 0 10px;
    border: 2px solid lightgrey;
    transition: all 0.3s ease;
  }
  .wrapper .option .dot{
    height: 20px;
    width: 20px;
    background: #d9d9d9;
    border-radius: 50%;
    position: relative;
  }
  .wrapper .option .dot::before{
    position: absolute;
    content: "";
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    background: #053f5c;
    border-radius: 50%;
    opacity: 0;
    transform: scale(1.5);
    transition: all 0.3s ease;
  }
  input[type="radio"]{
    display: none;
  }
  #option-1:checked:checked ~ .option-1,
  #option-2:checked:checked ~ .option-2,
  #option-3:checked:checked ~ .option-3,
  #option-4:checked:checked ~ .option-4{
    border-color: #053f5c;
    background: #053f5c;
  }
  #option-1:checked:checked ~ .option-1 .dot,
  #option-2:checked:checked ~ .option-2 .dot,
  #option-3:checked:checked ~ .option-3 .dot,
  #option-4:checked:checked ~ .option-4 .dot{
    background: #fff;
  }
  #option-1:checked:checked ~ .option-1 .dot::before,
  #option-2:checked:checked ~ .option-2 .dot::before,
  #option-3:checked:checked ~ .option-3 .dot::before,
  #option-4:checked:checked ~ .option-4 .dot::before{
    opacity: 1;
    transform: scale(1);
  }
  .wrapper .option span{
    font-size: 16px;
    color: #808080;
  }
  #option-1:checked:checked ~ .option-1 span,
  #option-2:checked:checked ~ .option-2 span,
  #option-3:checked:checked ~ .option-3 span,
  #option-4:checked:checked ~ .option-4 span{
    color: #fff;
  }


`;

export const styFlex = css`
  display: flex;
  justify-content: center;
`;
