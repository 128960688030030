import React, { Fragment } from 'react';
import { bool } from 'prop-types';
import { styWrapper } from '../HelloSection/styles';

function FooterSection({ isInvitation }) {
  return (
    <Fragment>
      {!isInvitation && (
        <div id="fh5co-couple" className="fh5co-section-gray" css={styWrapper}>
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                <h2 className="main-font">Terima Kasih</h2>
                <p className="info">
                Sehubungan dengan masa pandemi, tanpa mengurangi rasa hormat kepada Bapak/ Ibu/ Saudara/i, mohon maaf kami belum bisa mengundang Bapak/ Ibu/ Saudara/i semua untuk hadir di lokasi. 
                Bapak/ Ibu/ Saudara/i bisa menghadiri acara pernikahan kami secara virtual melalui kanal instagram yang tertera diatas.
                </p>
                <p className="info">
                Salam kami yang berbahagia
                Aprilea & Prastito
                Serta Keluarga Kedua Mempelai
                 <br />
                  Wassalamualaikum warahmatullahi wabarakatuh.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <footer id="fh5co-footer" role="contentinfo">
        <div className="container">
          <div className="row copyright">
            <div className="col-md-12 text-center">
              <p>
                <small className="block">2022 April & Tito Wedding</small>
                <br/>
                👽

              </p>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
}

FooterSection.propTypes = {
  isInvitation: bool.isRequired,
};

export default React.memo(FooterSection);
